import { ConfigurationDTO } from 'src/app/platform/dtos/configuration.dto';

export class GetConfigurationsAction {
    static readonly type = '[Configuration] [GET] [Configurations]';

    constructor(
        public organizationId: string,
        public studyId: string, 
        public applicationId: string) {
    }
}

export class GetDsmbConfigurationsAction {
    static readonly type = '[Configuration] [GET] [Dsmb Configurations]';

    constructor(
        public organizationId: string,
        public studyId: string
        ) {
    }
}

export class UpdateConfigurationsAction {
    static readonly type = '[Configuration] [POST] [Update Configurations]';

    constructor(
        public newConfigurations: ConfigurationDTO[], 
        public organizationId: string,
        public studyId: string, 
        public applicationId: string) {
    }
}

export class UploadTemplateAgendaAction {
    static readonly type = '[Configuration] [POST] [Upload Template Agenda]';

    constructor (
        public organizationId: string,
        public studyId: string,
        public template: FormData
    ) {}
}

export class UploadTemplateOutcomeAction {
    static readonly type = '[Configuration] [POST] [Upload Template Output]';

    constructor (
        public organizationId: string,
        public studyId: string,
        public template: FormData
    ) {}
}