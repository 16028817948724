import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { TasksState } from '../../dsmb/store/tasks/tasks.state';
import { NgxsNotificationsPluginModule } from './_plugins/notifications.plugin';
import { ApplicationState } from './application/application.state';
import { AuthState } from './auth/auth.state';
import { AUTH_STATE_LOCAL_STORAGE_KEYS } from './auth/auth.token';
import { HelpdeskState } from './helpdesk/helpdesk.state';
import { LogState } from './log/log.state';
import { OperationState } from './operation/operation.state';
import { OrganizationState } from './organization/organization.state';
import { PanelState } from './panel/panel.state';
import { ReportingState } from './reporting/reporting.state';
import { SidebarState } from './sidebar/sidebar.state';
import { SIDEBAR_STATE_LOCAL_STORAGE_KEYS } from './sidebar/sidebar.token';
import { StudyState } from './study/study.state';
import { TimezoneState } from './timezone/timezone.state';
import { UserState } from './user/user.state';
import { OPERATION_STATE_LOCAL_STORAGE_KEYS } from './operation/operation.token';
import { MeetingState } from '../../dsmb/store/meeting/meeting.state';
import { ConfigurationState } from 'src/app/dsmb/store/configurations/configuration.state';
import { DocumentState } from 'src/app/dsmb/store/document/document.state';
import { DocumentCountersignState } from 'src/app/dsmb/store/document-countersign/document-countersign.state';
import { MeetingPollState } from 'src/app/dsmb/store/meeting-poll/meeting-poll.state';
import { MeetingRecordingState } from 'src/app/dsmb/store/meeting-recording/meeting-recording.state';
import { UserMessageState } from './chat/chat.state';
import { DiscussionItemsState } from 'src/app/dsmb/store/discussion-items/discussion-items.state';
import { DecisionsState } from 'src/app/dsmb/store/decisions/decisions.state';
import { VersionState } from './version/version.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([
      ApplicationState,
      AuthState,
      HelpdeskState,
      LogState,
      OperationState,
      OrganizationState,
      SidebarState,
      StudyState,
      PanelState,
      ReportingState,
      TimezoneState,
      UserState,
      TasksState,
      MeetingState,
      MeetingPollState,
      ConfigurationState,
      DocumentState,
      DocumentCountersignState,
      MeetingRecordingState,
      UserMessageState,
      DiscussionItemsState,
      DecisionsState,
      VersionState
    ], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      key: AUTH_STATE_LOCAL_STORAGE_KEYS
        .concat(OPERATION_STATE_LOCAL_STORAGE_KEYS)
        .concat(SIDEBAR_STATE_LOCAL_STORAGE_KEYS)
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsNotificationsPluginModule.forRoot()
  ],
  providers: [ ],
  exports: [NgxsModule]
})
export class StoreModule {
}
