import { IUser, User } from "src/app/platform/model/user.model";

export class GetRequiredTasksAction {
    static readonly type = '[Tasks] [GET] [Required]';

    constructor(
      public organizationId: string,
      public studyId: string
    ) { }
}

export class GetUserTasksAction {
    static readonly type = '[Tasks] [GET]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public oDataQuery?: string
    ) { }
}

export class GetUserTaskDetailsAction {
    static readonly type = '[Tasks] [GET] [Details]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public taskId: string
    ) { }
}

export class GetTaskUsersAction {
    static readonly type = '[Tasks] [GET] [User]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public query: string
    ) { }
}

export class CreateUserTaskAction {
    static readonly type = '[Tasks] [POST] [User]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public payload: {
        title: string,
        description: string,
        discussionItemId: string,
        decisionId: string,
        meetingId: string,
        assigneeUserName: string,
        expirationDate: string
      },
      
    ) { }
}

export class AddUserTaskMessageAction {
  static readonly type = '[Tasks] [POST] [Message]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public taskId: string,
    public payload: {
      message: string
    }
  ) { }
}

export class DeleteUserTaskAction {
    static readonly type = '[Tasks] [POST] [Delete]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public taskId: string
    ) { }
}

export class CloseUserTaskAction {
    static readonly type = '[Tasks] [POST] [Close]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public taskId: string,
      public payload: {
        message?: string
      }
    ) { }
}

export class GetTasksByDiscussionItemAction {
  static readonly type = '[Tasks] [GET] [Get Tasks By Discussion Item]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public discussionItemId: string,
    public username: string,
    public allDecisions: boolean,
  ) { }
}


export class GetTasksByDecisionAction {
  static readonly type = '[Tasks] [GET] [Get Tasks By Decision]';

  constructor(
    public organizationId: string,
    public studyId: string,
    public decisionId: string,
  ) { }
}

export class   GetTasksByMeetingIdAction
 {
    static readonly type = '[Tasks] [GET] [Get Tasks By Meeting Id]';
  
    constructor(
      public organizationId: string,
      public studyId: string,
      public meetingId: string,
      public user: IUser,
    ) { }
}

export class   GetTasksCountByDiscussionItemIdAction
  {
    static readonly type = '[Tasks] [GET] [Get Tasks Count By Discussion Item Id]';
  
    constructor(
      public organizationId: string,
      public studyId: string,
      public discussionItemId: string,
      public username: string,
    ) { }
}

export class EditDueDateTaskAction{
  static readonly type = '[Tasks] [GET] [Edit Due Date]';
  
    constructor(
      public organizationId: string,
      public studyId: string,
      public taskId: string,
      public dueDate: string,
    ) { }
}