import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { environment } from '../../../../environments/environment';
import { ITask, IUserTask } from '../../interface/task.interface';
import { IUser } from '../../../platform/model/user.model';
import { HttpService } from '../../../platform/services/http.service';
import {
  CloseUserTaskAction,
  DeleteUserTaskAction,
  GetRequiredTasksAction,
  GetUserTaskDetailsAction,
  GetUserTasksAction,
  CreateUserTaskAction, AddUserTaskMessageAction, GetTaskUsersAction, GetTasksByDiscussionItemAction, GetTasksByMeetingIdAction, GetTasksByDecisionAction, EditDueDateTaskAction
} from './tasks.actions';
import { TASKS_STATE_TOKEN } from './tasks.token';
import moment from 'moment';
import { DateFormatEnum } from 'src/app/platform/enum/dates-format.enum';

export class ITasksState {
    requiredTasks: ITask[];
    userTasks: IUserTask[];
    taskUsers: IUser[];
    userTaskDetails: IUserTask;
    discussionItemTasks: IUserTask[];
    meetingTasks: IUserTask[];
    decisionTasks: IUserTask[];

}

@State({
    name: TASKS_STATE_TOKEN,
    defaults: TasksState.defaultState
})
@Injectable()
export class TasksState {
    static defaultState: ITasksState = {
      requiredTasks: [],
      userTasks: [],
      taskUsers: [],
      userTaskDetails: null,
      discussionItemTasks:[],
      meetingTasks:[],
      decisionTasks:[],
    };

    constructor(
        private readonly store: Store,
        private readonly httpService: HttpService
    ) { }

    @Selector()
    static getRequiredTasks(state: ITasksState) {
        return state.requiredTasks;
    }

    @Selector()
    static getTaskByDiscussionItem(state: ITasksState) {
        return state.discussionItemTasks;
    }

    @Selector()
    static getTaskByMeetingId(state: ITasksState) {
        return state.meetingTasks;
    }

    @Selector()
    static getUserTasks(state: ITasksState) {
        return state.userTasks;
    }

    @Selector()
    static getTaskUsers(state: ITasksState) {
      return state.taskUsers;
    }

    @Selector()
    static getUserTaskDetails(state: ITasksState) {
        return state.userTaskDetails;
    }

    @Selector()
    static getDecisionTasks(state: ITasksState){
      return state.decisionTasks
    }

    @Action(GetRequiredTasksAction)
    public getRequiredTasksAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId }: GetRequiredTasksAction) {
      return this.httpService.get<ITask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/tasks/dashboard`, {
        error: () => patchState({ requiredTasks: [] }),
        success: requiredTasks => patchState({ requiredTasks })
      });
    }

    @Action(GetUserTasksAction)
    public getUserTasksAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, oDataQuery }: GetUserTasksAction) {
      return this.httpService.get<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/tasks/user${oDataQuery ? `?${oDataQuery}` : ''}`, {
        error: () => patchState({ userTasks: [] }),
        success: userTasks => patchState({ userTasks })
      });
    }

    @Action(GetUserTaskDetailsAction)
    public getUserTaskDetailsAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, taskId }: GetUserTaskDetailsAction) {
      return this.httpService.get<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}`, {
        error: () => patchState({ userTaskDetails: null }),
        success: userTaskDetails => patchState({ userTaskDetails })
      });
    }

    @Action(GetTaskUsersAction)
    public getTaskUsersAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, query }: GetTaskUsersAction) {
      return this.httpService.get<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/users${query}`, {
        error: () => patchState({ taskUsers: null }),
        success: taskUsers => patchState({ taskUsers })
      });
    }

    @Action(CreateUserTaskAction)
    public createUserTaskAction({ getState, setState, patchState }: StateContext<ITasksState>, { organizationId, studyId, payload }: CreateUserTaskAction) {
      return this.httpService.post<IUserTask>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/add`, payload, {
        error: () => patchState({ userTaskDetails: null }),
        success: discussionItemTasks => {
          if (!discussionItemTasks.task.discussionItemId && !discussionItemTasks.task.decisionId) {
            // Action created but not linked to anything yet
            const state = getState();
            const userTasksList = [...state.userTasks];
            userTasksList.push(discussionItemTasks.task);
  
            setState({
              ...state,
              userTasks: userTasksList,
            });
          }
          else if (discussionItemTasks.task.discussionItemId && !discussionItemTasks.task.decisionId) {
            // It is an action linked to a discussion item
            const state = getState();
            const discussionItemList = [...state.discussionItemTasks];
            discussionItemList.push(discussionItemTasks.task);
  
            setState({
              ...state,
              discussionItemTasks: discussionItemList,
            });
          }
          else if (discussionItemTasks.task.discussionItemId && discussionItemTasks.task.decisionId) {
            // It is an action linked directly to a decision
            const state = getState();
            const decisionTasksList = [...state.decisionTasks];
            decisionTasksList.push(discussionItemTasks.task);
  
            setState({
              ...state,
              decisionTasks: decisionTasksList,
            });
          }
          
        }
      });
    }

    @Action(AddUserTaskMessageAction)
    public addUserTaskMessageAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, taskId, payload }: AddUserTaskMessageAction) {
      return this.httpService.post<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}/addMessage`, payload, {
        error: () => {},
        success: () => {}
      });
    }

    @Action(DeleteUserTaskAction)
    public deleteUserTaskAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, taskId }: DeleteUserTaskAction) {
      return this.httpService.post<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}/remove`, {}, {
        error: () => {},
        success: () => {}
      });
    }

    @Action(GetTasksByDiscussionItemAction)
    public getTasksByDiscussionItemAction({ patchState, getState }: StateContext<ITasksState>, {organizationId, studyId, discussionItemId, username, allDecisions}: GetTasksByDiscussionItemAction){
      let filter = allDecisions ? '?$filter=DecisionId ne \'NULL\'' : '?$filter=DecisionId eq null';
      return this.httpService.get<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/discussion-item/${discussionItemId}/tasks/${username}${filter}`, {
        error: () => {},
        success: discussionItemTasks => {
          if (allDecisions){
            patchState({decisionTasks: discussionItemTasks});
          }
          else {
            patchState({ discussionItemTasks });
          }
        }
      });
    }

    @Action(GetTasksByDecisionAction)
    public getTasksByDecisionAction({ patchState }: StateContext<ITasksState>, {organizationId, studyId, decisionId}: GetTasksByDecisionAction){
      return this.httpService.get<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/decision/${decisionId}/tasks`, {
        error: () => {},
        success: decisionTasks => patchState({ decisionTasks })
      });
    }

    @Action(GetTasksByMeetingIdAction)
    public getTasksByMeetingIdAction({ patchState }: StateContext<ITasksState>, {organizationId, studyId, meetingId, user}: GetTasksByMeetingIdAction){
      return this.httpService.get<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/meeting/${meetingId}/tasks/${user.name}`, {
        error: () => {},
        success: meetingTasks => {  
          meetingTasks.map(meetingTask => ({
            ...meetingTask
          }));        
          
          patchState({ meetingTasks })

        }
      });
    }

    @Action(CloseUserTaskAction)
    public closeUserTaskAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, taskId, payload }: CloseUserTaskAction) {
      return this.httpService.post<IUserTask[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}/close`, payload, {
        error: () => {},
        success: () => {}
      });
    }

    @Action(EditDueDateTaskAction)
    public editDueDateAction({ patchState }: StateContext<ITasksState>, { organizationId, studyId, taskId, dueDate }: EditDueDateTaskAction) {
      return this.httpService.post<any>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/task/${taskId}/edit`, { dueDate: dueDate }, {
        error: () => patchState({ userTaskDetails: null }),
        success: userTaskDetails => patchState({ userTaskDetails })
      });
    }
}
