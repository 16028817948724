import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IUserState } from '../../../platform/store/user/user.state';
import { MeetingAgendaDTO } from '../../dtos/meeting-agenda.dto';
import { MeetingAttendeeDTO } from '../../dtos/meeting-attendee.dto';
import { MeetingOutcomeDTO } from '../../dtos/meeting-outcome.dto';
import { MeetingStatusDTO } from '../../dtos/meeting-status.dto';
import { MeetingTemplateConfigurationDTO } from '../../dtos/meeting-template-configuration.dto';
import { MeetingSessionDTO } from '../../dtos/meeting-session.dto';
import { MeetingDTO } from '../../dtos/meeting.dto';
import { NewMeetingAttendeeDTO } from '../../dtos/new-meeting-attendees.dto';
import { MeetingService } from '../../services/meeting.service';
import {
  AddMeetingAction,
  AddMeetingAgendaAction,
  AddMeetingOutcomeAction,
  CancelMeetingAction,
  ConfirmMeetingAction,
  GetClosedMeetingsCountAction as GetCompletedMeetingsCountAction,
  GetMeetingAction,
  GetMeetingAgendaAction,
  GetMeetingAttendeesAction,
  GetMeetingOutcomeAction,
  GetMeetingsAction,
  GetMeetingsCountAction,
  GetMeetingStatusesAction,
  GetMeetingTemplateConfigurationAction,
  GetMeetingSessionsAction,
  GetPastMeetingsAction,
  GetPossibleNewMeetingAttendeesAction,
  GetUpcomingMeetingsAction,
  SaveMeetingAttendeesAction,
  ScheduleMeetingAction,
  ActivateMeetingAction,
  ResendMeetingAttendeeEmailAction,
  UpdateMeetingAgendaAction,
  UpdateMeetingDetailsAction,
  UpdateMeetingOutcomeAction,
  GetUpcomingMeetingsCountAction,
  GetUpcomingMeetings4OrganizationAction,
  GetPastMeetings4OrganizationAction,
  UpdateMeetingLinkAction,
  GetMeetingsByOrganizationAction,
  GetMeetingsAssociatedWithDiscussionItemsAction,
  GetMeetingsAssociatedWithDecisionsAction,
  GetMeetingDiscussionItemConfigurationAction,
  CompleteMeetingAction,
  GetMeetingTypesAction,
  ReOpenMeetingAction,
  RemoveMeetingAction
} from './meeting.actions';
import { MEETING_STATE_TOKEN } from './meeting.token';
import { MeetingDiscussionItemConfigurationDTO } from '../../dtos/meeting-discussion-item-configuration.dto';
import { MeetingTypeDTO } from '../../dtos/meeting-type.dto';

export class IMeetingState {
    meetings: MeetingDTO[];
    meetingsCount: number;
    organizationMeetings: MeetingDTO[];
    organizationMeetingsCount: number;
    // TODO: delete, use meetingDetails instead
    meetingDetail: MeetingDTO;
    attendees: MeetingAttendeeDTO[];
    statuses: MeetingStatusDTO[];
    sessions: MeetingSessionDTO[];
    agenda: MeetingAgendaDTO;
    outcome: MeetingOutcomeDTO;
    templateConfiguration: MeetingTemplateConfigurationDTO;
    upcomingMeetings: MeetingDTO[];
    upcomingMeetingsCount: number;
    pastMeetings: MeetingDTO[];
    newMeetingPossibleAttendees: NewMeetingAttendeeDTO;
    meetingDetails: Record<string, MeetingDTO>;
    upcomingMeetings4Organization: MeetingDTO[];
    pastMeetings4Organization: MeetingDTO[];
    meetingsAssociatedWithDiscussionItems: MeetingDTO[];
    meetingsAssociatedWithDecisions: MeetingDTO[];
    meetingTypes: MeetingTypeDTO[];
    meetingDiscussionItemConfiguration: MeetingDiscussionItemConfigurationDTO[];
}

@State({
    name: MEETING_STATE_TOKEN,
    defaults: MeetingState.defaultState
})
@Injectable()
export class MeetingState {
    static defaultState: IMeetingState = {
        meetings: [],
        meetingsCount: null,
        organizationMeetings: [],
        organizationMeetingsCount: null,
        meetingDetail: null,
        attendees: [],
        statuses: [],
        sessions: [],
        agenda: null,
        outcome: null,
        templateConfiguration: null,
        upcomingMeetings: [],
        upcomingMeetingsCount: null,
        pastMeetings: [],
        newMeetingPossibleAttendees: null,
        meetingDetails: {},
        upcomingMeetings4Organization: [],
        pastMeetings4Organization: [],
        meetingsAssociatedWithDiscussionItems: [],
        meetingsAssociatedWithDecisions: [],
        meetingTypes: [],
        meetingDiscussionItemConfiguration: [],
    };

    constructor(
        private readonly store: Store,
        private readonly meetingService: MeetingService
    ) { }

    // TODO: delete, use getMeetingDetails instead
    @Selector()
    static getMeeting(state: IMeetingState) {
        return state.meetingDetail;
    }

    @Selector()
    static getMeetingDetails(id: string) {
        return createSelector([MeetingState], ({ meeting: { meetingDetails } }) => {
          return meetingDetails[id];
        });
    }

    @Selector()
    static getAttendees(state: IMeetingState) {
        return state.attendees;
    }

    @Action(GetMeetingAction)
    getMeeting({ patchState, getState }: StateContext<IMeetingState>, { organizationId, studyId, meetingId }: GetMeetingAction) {
        return this.meetingService.getMeeting(organizationId, studyId, meetingId).pipe(
          tap(meetingDetail => {
            patchState({ 
                meetingDetail, 
                meetingDetails: { ...(getState().meetingDetails), [meetingId]: meetingDetail }})})
        );
    }

    @Action(AddMeetingAction)
    addMeeting({ getState, setState }: StateContext<IMeetingState>, { organizationId, studyId, meeting }: AddMeetingAction) {
        return this.meetingService.addMeeting(organizationId, studyId, meeting).pipe(tap(meeting => {
            // Add new meeting to the list
            const state = getState();
            const meetingList = [...state.meetings];
            meetingList.push(meeting);

            setState({
                ...state,
                meetings: meetingList,
            });
        }));
    }

    @Action(UpdateMeetingLinkAction)
    updateMeetingLinkAction({ getState, patchState }: StateContext<IMeetingState>, { organizationId, studyId, meetingId, payload }: UpdateMeetingLinkAction) {
        return this.meetingService.updateMeetingLink(organizationId, studyId, meetingId, payload).pipe(tap(() => {
            const state = getState();
            const meeting = {...state.meetingDetail};

            meeting.meetingAppLink = payload.link;
            meeting.password = payload.password;

            patchState({ meetingDetail: meeting });
        }));
    }

    @Action(UpdateMeetingDetailsAction)
    updateMeeting({ getState, setState, patchState }: StateContext<IMeetingState>,
                  { organizationId, studyId, meetingId, meeting }: UpdateMeetingDetailsAction) {
        return this.meetingService.updateMeeting(organizationId, studyId, meetingId, meeting).pipe(tap(meeting => {
            // Update the meeting details
            patchState({ meetingDetail: meeting });

            // Update the meeting in the list
            const state = getState();
            const meetingList = [...state.meetings];
            const updatedMeetingIndex = meetingList.findIndex(m => m.id === meetingId);
            meetingList[updatedMeetingIndex] = meeting;

            setState({
                ...state,
                meetings: meetingList,
            });
        }));
    }

    @Action(GetMeetingAttendeesAction)
    getMeetingAttendees({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, meetingId }: GetMeetingAttendeesAction) {
        return this.meetingService.getMeetingAttendees(organizationId, studyId, meetingId).pipe(tap(attendees => {
            patchState({ attendees });
        }),
            // So that in case method return empty list, attendees list get emptied
            catchError((error: HttpErrorResponse) => {
                if (error.status === 404) {
                    patchState({ attendees: [] });
                }

                return throwError(() => error);
            }));
    }

    @Action(SaveMeetingAttendeesAction)
    saveMeetingAttendees({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, meetingId, newAttendees }: SaveMeetingAttendeesAction) {
        return this.meetingService.updateMeetingAttendees(organizationId, studyId, meetingId, newAttendees);
    }

    @Action(ScheduleMeetingAction)
    scheduleMeeting({ getState, setState, patchState, dispatch }: StateContext<IMeetingState>,
                    { organizationId, studyId, meetingId, meetingScheduled }: ScheduleMeetingAction) {
        return this.meetingService.scheduleMeeting(organizationId, studyId, meetingId, meetingScheduled).pipe(
            tap(meetingScheduled => {
                // Update the meetingDetails
                patchState({ meetingDetail: meetingScheduled.meetingDetails });

                // Update the attendees
                patchState({ attendees: meetingScheduled.meetingAttendees });

                // Update the meeting list
                const state = getState();
                const meetingList = [...state.meetings];
                const updatedMeetingIndex = meetingList.findIndex(m => m.id === meetingId);
                meetingList[updatedMeetingIndex] = meetingScheduled.meetingDetails;

                setState({
                    ...state,
                    meetings: meetingList,
                });
                // Added due to bug not display agenda when meeting is closed without saving agenda
                dispatch(new GetMeetingAgendaAction(organizationId, studyId, meetingId));
            })
        );
    }

    @Action(ActivateMeetingAction)
    activateMeeting({ getState, setState, dispatch, patchState }: StateContext<IMeetingState>, { organizationId, studyId, meetingId, meeting }: ActivateMeetingAction) {
        return this.meetingService.activateMeeting(organizationId, studyId, meetingId, meeting).pipe(
            tap(meeting => {
                // Update the meetingDetails
                patchState({ meetingDetail: meeting.meetingDetails });

                // Update the attendees
                patchState({ attendees: meeting.meetingAttendees });

                // Update the meeting list
                const state = getState();
                const meetingList = [...state.meetings];
                const updatedMeetingIndex = meetingList.findIndex(m => m.id === meetingId);
                meetingList[updatedMeetingIndex] = meeting.meetingDetails;

                setState({
                    ...state,
                    meetings: meetingList,
                });
                // Added due to bug not display agenda when meeting is closed without saving agenda
                dispatch(new GetMeetingAgendaAction(organizationId, studyId, meetingId));                
                dispatch(new GetMeetingOutcomeAction(organizationId, studyId, meetingId));
            })
        );
    }

    @Action(CompleteMeetingAction)
    completeMeeting({ getState, setState, dispatch, patchState }: StateContext<IMeetingState>, { organizationId, studyId, meetingId, meeting }: CompleteMeetingAction) {
        return this.meetingService.completeMeeting(organizationId, studyId, meetingId, meeting).pipe(
            tap(meeting => {
                // Update the meetingDetails
                patchState({ meetingDetail: meeting.meetingDetails });

                // Update the attendees
                patchState({ attendees: meeting.meetingAttendees });

                // Update the attendees
                patchState({ outcome: meeting.meetingOutcome });

                // Update the meeting list
                const state = getState();
                const meetingList = [...state.meetings];
                const updatedMeetingIndex = meetingList.findIndex(m => m.id === meetingId);
                meetingList[updatedMeetingIndex] = meeting.meetingDetails;

                setState({
                    ...state,
                    meetings: meetingList
                });

                // Added due to bug not display agenda and outcome when meeting is closed without saving outcome
                dispatch(new GetMeetingAgendaAction(organizationId, studyId, meetingId));
                dispatch(new GetMeetingOutcomeAction(organizationId, studyId, meetingId));
            })
        );
    }

    @Action(ConfirmMeetingAction)
    confirmMeeting({ getState, setState, patchState }: StateContext<IMeetingState>,
                 { organizationId, studyId, meetingId, meetingConfirmed }: ConfirmMeetingAction) {
        return this.meetingService.confirmMeeting(organizationId, studyId, meetingId, meetingConfirmed).pipe(
            tap(meetingConfirmed => {
                // Update meeting details
                patchState({ meetingDetail: meetingConfirmed });

                // Update the meeting list
                const state = getState();
                const meetingList = [...state.meetings];
                const updatedMeetingIndex = meetingList.findIndex(m => m.id === meetingId);
                meetingList[updatedMeetingIndex] = meetingConfirmed;

                setState({
                    ...state,
                    meetings: meetingList,
                });
            })
        );
    }

    @Action(ResendMeetingAttendeeEmailAction)
    resendMeetingAttendeeEmail({ patchState }: StateContext<IUserState>, { organizationId, studyId, meetingId, meetingResendNotifications }: ResendMeetingAttendeeEmailAction) {
      return this.meetingService.sendAttendeeEmail(organizationId, studyId, meetingId, meetingResendNotifications);
    }

    @Action(CancelMeetingAction)
    cancelMeeting({ getState, setState, patchState }: StateContext<IMeetingState>,
                 { organizationId, studyId, meetingId, meetingCancelled }: CancelMeetingAction) {
        return this.meetingService.cancelMeeting(organizationId, studyId, meetingId, meetingCancelled).pipe(
            tap(meetingCancelled => {
                // Update meeting details
                patchState({ meetingDetail: meetingCancelled.meetingDetails });

                // Update the attendees
                patchState({ attendees: meetingCancelled.meetingAttendees });

                // Update the meeting list
                const state = getState();
                const meetingList = [...state.meetings];
                const updatedMeetingIndex = meetingList.findIndex(m => m.id === meetingId);
                meetingList[updatedMeetingIndex] = meetingCancelled.meetingDetails;

                setState({
                    ...state,
                    meetings: meetingList,
                });
            })
        );
    }

    @Action(RemoveMeetingAction)
    removeMeeting({ getState, setState, patchState }: StateContext<IMeetingState>,
                 { organizationId, studyId, meetingId, meetingRemoved }: RemoveMeetingAction) {
        return this.meetingService.removeMeeting(organizationId, studyId, meetingId, meetingRemoved).pipe(
            tap(meetingCancelled => {
                // Update meeting details
                patchState({ meetingDetail: meetingRemoved.meetingDetails });

                // Update the attendees
                patchState({ attendees: meetingRemoved.meetingAttendees });

                // Update the meeting list
                const state = getState();
                const meetingList = [...state.meetings];
                const updatedMeetingIndex = meetingList.findIndex(m => m.id === meetingId);
                meetingList[updatedMeetingIndex] = meetingRemoved.meetingDetails;

                setState({
                    ...state,
                    meetings: meetingList,
                });
            })
        );
    }


    @Action(ReOpenMeetingAction)
    reOpenMeeting({ getState, setState, patchState }: StateContext<IMeetingState>,
                { organizationId, studyId, meetingId, meetingReOpened}: ReOpenMeetingAction) {
        return this.meetingService.reOpenMeeting(organizationId, studyId, meetingId, meetingReOpened).pipe(
            tap(meetingReOpened => {
                // Update meeting details
                patchState({ meetingDetail: meetingReOpened });

                // Update the meeting list
                const state = getState();
                const meetingList = [...state.meetings];
                const updatedMeetingIndex = meetingList.findIndex(m => m.id === meetingId);
                meetingList[updatedMeetingIndex] = meetingReOpened;

                const meetingsCount = state.meetingsCount + 1;

                setState({
                    ...state,
                    meetings: meetingList,
                    meetingsCount: meetingsCount,
                });
            })
        )
    }

    // Meetings

    @Selector()
    static getMeetings(state: IMeetingState) {
        return state.meetings;
    }

    @Selector()
    static getOrganizationMeetings(state: IMeetingState) {
        return state.organizationMeetings;
    }

    @Selector()
    static getUpcomingMeetings(state: IMeetingState) {
        return state.upcomingMeetings;
    }

    @Selector()
    static getUpcomingMeetingsCount(state: IMeetingState) {
        return state.upcomingMeetingsCount;
    }

    @Selector()
    static getPastMeetings(state: IMeetingState) {
        return state.pastMeetings;
    }

    @Selector()
    static getMeetingsCount(state: IMeetingState) {
        return state.meetingsCount;
    }

    @Selector()
    static getUpcomingMeetings4Organization(state: IMeetingState) {
        return state.upcomingMeetings4Organization;
    }

    @Selector()
    static getPastMeetings4Organization(state: IMeetingState) {
        return state.pastMeetings4Organization;
    }

    @Selector()
    static getMeetingsAssociatedWithDiscussionItems(state: IMeetingState) {
        return state.meetingsAssociatedWithDiscussionItems;
    }

    @Selector()
    static getMeetingsAssociatedWithDecisions(state: IMeetingState) {
        return state.meetingsAssociatedWithDecisions;
    }

    @Action(GetMeetingsAction)
    getMeetings({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, filter, startPage, itemsPerPage, sorting }: GetMeetingsAction) {
        return this.meetingService.getMeetings(organizationId, studyId, filter, startPage, itemsPerPage, sorting).pipe(tap(meetings => {
                patchState({ meetings });
            }),
            catchError((...args) => {
                if (args[0].status === 404) {
                    patchState({ meetings: [] });
                }

                return throwError(() => args[0]);
            })
        );
    }

    @Action(GetMeetingsByOrganizationAction)
    getMeetingByOrganization({ patchState }: StateContext<IMeetingState>, { organizationId, filter, startPage, itemsPerPage, sorting }: GetMeetingsByOrganizationAction){
        return this.meetingService.getMeetingsByOrganization(organizationId, filter, startPage, itemsPerPage, sorting).pipe(tap(organizationMeetings => {
            patchState({ organizationMeetings });
        }),
        catchError((...args) => {
            if (args[0].status === 404) {
                patchState({ organizationMeetings: [] });
            }

            return throwError(() => args[0]);
        })
    );
    }

    @Action(GetMeetingsCountAction)
    getMeetingsCount({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, filter }: GetMeetingsCountAction) {
        return this.meetingService.getMeetingsCount(organizationId, studyId, filter)
            .pipe(tap(meetingsCount => patchState({ meetingsCount })));
    }

    @Action(GetUpcomingMeetingsAction)
    getUpcomingMeetings({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, topCondition}: GetUpcomingMeetingsAction) {
        return this.meetingService.getUpcomingMeetings(organizationId, studyId, topCondition).pipe(
          tap(upcomingMeetings => patchState({ upcomingMeetings })),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              patchState({ upcomingMeetings: [] });
            }

            return throwError(() => error);
          })
        );
    }

    @Action(GetUpcomingMeetingsCountAction)
    getUpcomingMeetingCount({ patchState }: StateContext<IMeetingState>, { organizationId, studyId }: GetUpcomingMeetingsCountAction) {
        return this.meetingService.getUpcomingMeetingsCount(organizationId, studyId).pipe(
            tap(upcomingMeetingsCount => patchState({ upcomingMeetingsCount })),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 404){
                    patchState({ upcomingMeetingsCount: null });
                }
                return throwError(() => error);
            })
            );
    }

    @Action(GetPastMeetingsAction)
    getPastMeetings({ patchState }: StateContext<IMeetingState>, { organizationId, studyId }: GetPastMeetingsAction) {
        return this.meetingService.getPastMeetings(organizationId, studyId).pipe(
          tap(pastMeetings => patchState({ pastMeetings })),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              patchState({ pastMeetings: [] });
            }

            return throwError(() => error);
          })
        );
    }

    @Action(GetCompletedMeetingsCountAction)
    getCompletedMeetingsCount({ patchState}: StateContext<IMeetingState>, { organizationId, studyId }: GetCompletedMeetingsCountAction) {
        return this.meetingService.getCompletedMeetingsCount(organizationId, studyId).pipe(tap(meetingsCount => patchState({ meetingsCount })));
    }

    @Action(GetUpcomingMeetings4OrganizationAction)
    getUpcomingMeetings4Organization({ patchState}: StateContext<IMeetingState>, { organizationId, timeCondition }: GetUpcomingMeetings4OrganizationAction) {
        return this.meetingService.getUpcomingMeetings4Organization(organizationId, timeCondition).pipe(tap(upcomingMeetings4Organization => patchState({ upcomingMeetings4Organization })));
    }

    @Action(GetPastMeetings4OrganizationAction)
    getPastMeetings4Organization({ patchState}: StateContext<IMeetingState>, { organizationId, timeCondition }: GetPastMeetings4OrganizationAction) {
        return this.meetingService.getPastMeetings4Organization(organizationId, timeCondition).pipe(tap(pastMeetings4Organization => patchState({ pastMeetings4Organization })));
    }

    @Action(GetMeetingsAssociatedWithDiscussionItemsAction)
    getMeetingAssociatedWithDiscussionItems({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, filter }: GetMeetingsAssociatedWithDiscussionItemsAction) {
        return this.meetingService.getMeetingsAssociatedWithDiscussionItems(organizationId, studyId, filter).pipe(tap(meetingsAssociatedWithDiscussionItems => {
                patchState({ meetingsAssociatedWithDiscussionItems });
            }),
            catchError((...args) => {
                if (args[0].status === 404) {
                    patchState({ meetings: [] });
                }

                return throwError(() => args[0]);
            })
        );
    }

    @Action(GetMeetingsAssociatedWithDecisionsAction)
    getMeetingsAssociatedWithDecisions({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, filter }: GetMeetingsAssociatedWithDecisionsAction) {
        return this.meetingService.getMeetingsAssociatedWithDecisions(organizationId, studyId, filter).pipe(tap(meetingsAssociatedWithDecisions => {
                patchState({ meetingsAssociatedWithDecisions });
            }),
            catchError((...args) => {
                if (args[0].status === 404) {
                    patchState({ meetings: [] });
                }

                return throwError(() => args[0]);
            })
        );
    }

    // Meeting Status ------------------------------------------------------------------------------------------------------
    @Selector()
    static getMeetingStatuses(state: IMeetingState) {
        return state.statuses;
    }

    @Action(GetMeetingStatusesAction)
    getMeetingStatuses({ patchState }: StateContext<IMeetingState>) {
        const currentState = this.store.selectSnapshot(MEETING_STATE_TOKEN);

        if (!(currentState.statuses.length > 0)) {
            return this.meetingService.getMeetingStatuses()
                .pipe(tap(statuses => patchState({ statuses })));
        }
    }

    // Meeting Sessions ------------------------------------------------------------------------------------------------------
    @Selector()
    static getMeetingSessions(state: IMeetingState) {
        return state.sessions;
    }

    @Action(GetMeetingSessionsAction)
    getMeetingSessions({ patchState }: StateContext<IMeetingState>) {
        const currentState = this.store.selectSnapshot(MEETING_STATE_TOKEN);

        if (!(currentState.sessions.length > 0)) {
            return this.meetingService.getMeetingSessions()
                .pipe(tap(sessions => patchState({ sessions: sessions })));
        }
    }

    // Meeting template configuration

    @Selector()
    static getTemplateConfiguration(state: IMeetingState) {
        return state.templateConfiguration;
    }

    @Action(GetMeetingTemplateConfigurationAction)
    getMeetingTemplateConfiguration({ patchState }: StateContext<IMeetingState>,
                                    { organizationId, studyId, meetingSession }: GetMeetingTemplateConfigurationAction) {
    return this.meetingService.getMeetingTemplateConfiguration(organizationId, studyId, meetingSession)
        .pipe(tap(templateConfiguration => patchState({ templateConfiguration })));
    }

    // Meeting Type Configuration
    @Selector()
    static getMeetingTypes(state: IMeetingState) {
        return state.meetingTypes;
    }

    @Selector()
    static getMeetingDiscussionItemConfiguration(state: IMeetingState) {
        return state.meetingDiscussionItemConfiguration;
    }

    @Action(GetMeetingTypesAction)
    getMeetingTypes({ patchState }: StateContext<IMeetingState>, { organizationId, studyId }: GetMeetingTypesAction) {
        return this.meetingService.getMeetingTypes(organizationId, studyId)
            .pipe(tap(meetingTypes => patchState({ meetingTypes })))
    }

    @Action(GetMeetingDiscussionItemConfigurationAction)
    getMeetingDiscussionItemConfiguration({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, meetingType }: GetMeetingDiscussionItemConfigurationAction) {
        return  this.meetingService.getMeetingDiscussionItemConfiguration(organizationId, studyId, meetingType)
            .pipe(tap(meetingDiscussionItemConfiguration => patchState({ meetingDiscussionItemConfiguration: meetingDiscussionItemConfiguration })));
    }

    // Meeting Agenda ------------------------------------------------------------------------------------------------------

    @Selector()
    static getAgenda(state: IMeetingState) {
        return state.agenda;
    }

    // Action to add a new meeting agenda to the meeting
    @Action(AddMeetingAgendaAction)
    addMeetingAgenda({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, meetingId, meetingAgenda }: AddMeetingAgendaAction) {
        return this.meetingService.addMeetingAgenda(organizationId, studyId, meetingId, meetingAgenda)
            .pipe(tap(agenda => patchState({ agenda })));
    }

    // Action to get the meeting agenda of a meeting
    @Action(GetMeetingAgendaAction)
    getMeetingAgenda({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, meetingId }: GetMeetingAgendaAction) {
        return this.meetingService.getMeetingAgenda(organizationId, studyId, meetingId)
            .pipe(tap(agenda => patchState({ agenda }))
                // Added catchError to empty the agenda due to bug that would cause a meeting without agenda
                // to display the agenda of a previously accessed meeting with the agenda
                , catchError((error: HttpErrorResponse) => {
                    if (error.status === 404) {
                        patchState({ agenda: null });
                    }

                    return throwError(() => error);
                })
            );
    }

    // Action to update a meeting agenda
    @Action(UpdateMeetingAgendaAction)
    updateMeetingAgenda({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, meetingId, meetingAgenda }: UpdateMeetingAgendaAction) {
        return this.meetingService.updateMeetingAgenda(organizationId, studyId, meetingId, meetingAgenda)
            .pipe(tap(agenda => patchState({ agenda })));
    }

    // Meeting Outcome ------------------------------------------------------------------------------------------------------

    @Selector()
    static getOutcome(state: IMeetingState) {
        return state.outcome;
    }

    // Action to add a new meeting outcome to the meeting
    @Action(AddMeetingOutcomeAction)
    addMeetingOutcome({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, meetingId, meetingOutcome }: AddMeetingOutcomeAction) {
        return this.meetingService.addMeetingOutcome(organizationId, studyId, meetingId, meetingOutcome)
            .pipe(tap(outcome => patchState({ outcome })));
    }

    // Action to get the meeting outcome of a meeting
    @Action(GetMeetingOutcomeAction)
    getMeetingOutcome({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, meetingId }: GetMeetingOutcomeAction) {
        return this.meetingService.getMeetingOutcome(organizationId, studyId, meetingId)
            .pipe(tap(outcome => patchState({ outcome }))
                // Added catchError to empty the agenda due to bug that would cause a meeting without agenda
                // to display the agenda of a previously accessed meeting with the agenda
                , catchError((error: HttpErrorResponse) => {
                    if (error.status === 404) {
                        patchState({ outcome: null });
                    }

                    return throwError(() => error);
                }));

    }

    // Action to update a meeting outcome
    @Action(UpdateMeetingOutcomeAction)
    updateMeetingOutcome({ patchState }: StateContext<IMeetingState>, { organizationId, studyId, meetingId, meetingOutcome }: UpdateMeetingOutcomeAction) {
        return this.meetingService.updateMeetingOutcome(organizationId, studyId, meetingId, meetingOutcome)
            .pipe(tap(outcome => patchState({ outcome })));
    }


    @Selector()
    static getPossibleMeetingAttendees(state: IMeetingState) {
        return state.newMeetingPossibleAttendees;
    }

    @Action(GetPossibleNewMeetingAttendeesAction)
    getPossibleMeetingAttendees({ patchState }: StateContext<IMeetingState>,
                                { organizationId, studyId, meetingSession }: GetPossibleNewMeetingAttendeesAction) {
        return this.meetingService.getPossibleNewMeetingAttendees(organizationId, studyId, meetingSession)
            .pipe(tap(newMeetingPossibleAttendees => patchState({ newMeetingPossibleAttendees })));
    }
}
