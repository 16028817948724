import { MeetingId } from '../../../platform/utils/types';

export class GetDocumentsAction {
    static readonly type = '[Document] [GET] [Get Documents]';

    constructor(
      public params: {
        organizationId:string, 
        studyId: string,
        meetingId: MeetingId,
        pageData?: {
          filter: string,
          startPage?: number,
          itemsPerPage?: number,
          sorting?: string
        }
      }
    ) { }
}

export class GetDocumentsCountAction {
    static readonly type = '[Document] [GET] [Get Documents Count]';

    constructor(
      public params: {
        organizationId:string,
        studyId: string,
        meetingId: MeetingId,
        pageData?: {
          filter: string
        }
      }
    ) { }
}

export class UploadDocumentAction {
    static readonly type = '[Document] [POST] [Upload Document]';

    constructor(
      public meetingDocument: FormData,
      public organizationId:string,
      public studyId: string,
      public meetingId: MeetingId,
      public documentId: string,
      public versionNo: number
    ) { }
}

export class GetDocumentAction {
    static readonly type = '[Document] [GET] [Get Document]';

    constructor(
      public organizationId:string,
      public studyId: string,
      public meetingId: MeetingId,
      public documentId: string,
      public versionNo: string
    ) { }
}

export class DownloadMeetingDocumentBlob {
    static readonly type = '[Document] [GET] [Download Document Blob]';

    constructor(
      public organizationId: string,
      public studyId: string,
      public meetingId: MeetingId,
      public documentId: string,
      public versionId: string
    ) { }
}

export class DownloadStudyDocumentBlob {
    static readonly type = '[Document] [GET] [Download Study Document Blob]';

    constructor(
      public organizationId:string,
      public studyId: string,
      public documentId: string,
      public versionId: string
    ) { }
}

export class DownloadDocumentBase64 {
    static readonly type = '[Document] [GET] [Download Document Base64]';

    constructor(
      public organizationId:string,
      public studyId: string,
      public meetingId: MeetingId,
      public documentId: string,
      public versionId: string
    ) { }
}

export class DownloadStudyDocumentBase64 {
    static readonly type = '[Document] [GET] [Download Study Document Base64]';

    constructor(
      public organizationId:string,
      public studyId: string,
      public documentId: string,
      public versionId: string
    ) { }
}

export class DeleteMeetingDocument {
    static readonly type = '[Document] [Delete] [Delete Document]';

    public readonly post = {
      notification: {
        success: 'dsmb.delete-document.toastr-message'
      }
    };

    constructor(
      public organizationId: string, 
      public studyId: string,
      public meetingId: MeetingId,
      public documentId: string
    ) { }
}

export class PropagateUpdateDocument {
  static readonly type = '[Document] [Delete] [TestDispatch]';

  constructor(
    public documentId: string
  ) { }
}

export class DeleteStudyDocument {
    static readonly type = '[Document] [Delete] [Delete Study Document]';

    public readonly post = {
      notification: {
        success: 'dsmb.delete-document.toastr-message'
      }
    };

    constructor(
      public organizationId: string, 
      public studyId: string,
      public documentId: string
    ) { }
}

export class GetStudyDocumentsAction {
    static readonly type = '[Document] [GET] [Get Study Documents]';

    constructor(
      public organizationId:string,
      public studyId: string,
      public filter: string,
      public startPage?: number,
      public itemsPerPage?: number,
      public sorting?: string
    ) { }
}

export class GetStudyDocumentsCountAction {
    static readonly type = '[Document] [GET] [Get Study Documents Count]';

    constructor(
      public organizationId:string, 
      public studyId: string,
      public filter: string
    ) { }
}

export class GetStudyDocumentsUploadedCountAction {
    static readonly type = '[Document] [GET] [Get Study Documents Uploaded Count]';

    constructor(
      public organizationId:string,
      public studyId: string
    ) { }
}

export class UploadStudyDocumentAction {
    static readonly type = '[Document] [POST] [Upload Study Document]';

    public readonly post = {
      notification: {
        success: 'dsmb-update-document.toastr-message'
      }
    };

    constructor(
      public payload: {
        studyDocument: FormData,
        organizationId:string,
        studyId: string,
        documentId: string,
        versionNo: number
      }
    ) { }
}

export class GetStudyDocumentAction {
    static readonly type = '[Document] [GET] [Get Study Document]';

    constructor(
      public organizationId:string, 
      public studyId: string,
      public documentId: string,
      public versionNo: string
    ) { }
}

export class GetDocumentUploadersAction {
    static readonly type = '[Document] [GET] [Get Document Uploaders]';

    constructor(
      public organizationId:string, 
      public studyId: string
    ) { }
}

export class GetDocumentMeetingTitlesAction {
    static readonly type = '[Document] [GET] [Get Document Meeting Titles]';

    constructor(
      public organizationId:string,
      public studyId: string
    ) { }
}

export class GetAuditTrailDocumentAction {
    static readonly type = '[Document] [GET] [Get Document Audit Trail]';

    constructor(
      public payload: {
        organizationId:string,
        studyId: string,
        documentId: string,
        isStudyDocument: boolean,
        startPage?: number,
        itemsPerPage?: number,
        sorting?: string
      }
    ) { }
}

export class GetAuditTrailDocumentCountAction {
    static readonly type = '[Document] [Get] [Get Count Document Audit Trail]';

    constructor(
      public payload: {
        organizationId: string,
        studyId: string,
        documentId: string,
        isStudyDocument: boolean
      }
    ) { }
}

export class GetDocumentToCountersignAction {
    static readonly type = '[Document] [GET] [Get Document To Countersign]';

    constructor(
      public organizationId:string,
      public studyId: string, 
      public startPage?: number, 
      public itemsPerPage?: number
    ) { }
}

export class GetDocumentToCountersignCountAction {
    static readonly type = '[Document] [GET] [Get Document To Countersign Count]';

    constructor(
      public organizationId:string,
      public studyId: string
    ) { }
}

export class GetLastDocumentsUploadedAction {
    static readonly type = '[Document] [GET] [Get Last Document Uploaded]';

    constructor(
      public organizationId:string,
      public studyId: string
    ) { }
}
