import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ConfigurationDTO } from 'src/app/platform/dtos/configuration.dto';
import { ConfigurationService } from 'src/app/platform/services/configuration.service';
import { CONFIGURATION_STATE_TOKEN } from './configuration.token';

import {
    GetConfigurationsAction,
    GetDsmbConfigurationsAction,
    UpdateConfigurationsAction,
    UploadTemplateAgendaAction,
    UploadTemplateOutcomeAction
} from './configuration.action';

export class IConfigurationState {
    configurations: ConfigurationDTO[];
}

/*
    Remember to add ConfigurationState to:
    store.module.ts
    logout.plugin.ts
*/

@State({
    name: CONFIGURATION_STATE_TOKEN,
    defaults: ConfigurationState.defaultState
})
@Injectable()
export class ConfigurationState {
    static defaultState: IConfigurationState = {
        configurations: []
    };

    constructor(
        private readonly store: Store,
        private readonly configurationService: ConfigurationService,
    ) {
    }

    @Selector()
    static getConfigurations(state: IConfigurationState) {
        return state.configurations;
    }

    @Action(GetConfigurationsAction)
    getConfigurations({ patchState }: StateContext<IConfigurationState>, { organizationId, studyId, applicationId }: GetConfigurationsAction) {
        return this.configurationService.getConfigurations(organizationId, studyId, applicationId).pipe(tap(configurations => {
            patchState({ configurations });
        }));
    }

    @Action(GetDsmbConfigurationsAction)
    getDsmbConfigurations({ patchState }: StateContext<IConfigurationState>, { organizationId, studyId }: GetConfigurationsAction) {
        return this.configurationService.getDsmbConfigurations(organizationId, studyId).pipe(tap(configurations => {
            patchState({ configurations });
        }));
    }

    @Action(UpdateConfigurationsAction)
    updateConfigurations({ patchState }: StateContext<IConfigurationState>, { newConfigurations, organizationId, studyId, applicationId }: UpdateConfigurationsAction) {
        return this.configurationService.updateConfigurations(newConfigurations, organizationId, studyId, applicationId).pipe(tap(configurations => {
            patchState({ configurations });
        }));;
    }

    @Action(UploadTemplateAgendaAction)
    uploadTemplateAgenda({ patchState }: StateContext<IConfigurationState>, { organizationId, studyId, template}: UploadTemplateAgendaAction) {
        return this.configurationService.uploadTemplateAgenda(organizationId, studyId, template).pipe(tap(configurations => {
            patchState({ configurations });
        }));
    }

    @Action(UploadTemplateOutcomeAction)
    uploadTemplateOutcome({ patchState }: StateContext<IConfigurationState>, { organizationId, studyId, template }: UploadTemplateOutcomeAction) {
        return this.configurationService.uploadTemplateOutcome(organizationId, studyId, template).pipe(tap(configurations => {
            patchState({ configurations });
        }));
    }
}

