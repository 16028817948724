import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { ISidebarItem } from '../../interfaces/sidebar-item.interface';
import { SidebarState } from '../../store/sidebar/sidebar.state';
import { AuthService } from '../../services/auth.service';

// TODO: delete component/route, move logic to AuthState.authActionsLogin
@UntilDestroy()
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  @Select(SidebarState.getSidebar) private readonly sidebar$: Observable<ISidebarItem[]>;

  public sidebarItems: any = [];

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
  ) { }

  public ngOnInit(): void {
    this.sidebar$.pipe(
      untilDestroyed(this),
      tap(sidebarItems => {
        this.sidebarItems = sidebarItems;
      }),
      filter(sidebarItems => !!sidebarItems[0]),
      tap(sidebarItems => {
        let item = sidebarItems[0];

        if (item.type === 'page') {
          // TODO: Find solution to setTimeout - Remove setTimeout from component
          // The router.navigate call in this component were surrendered by a setTimeout call to avoit problem of users 
          // staying stuck in the Home Component trying to get redirected to the correct landing page. 
          // The reason why this happen was not found but having a timeout before the redirect solve the problem
          // A proper solution still need to be found
          setTimeout(() => {
            void this.router.navigate([item.link]);
          }, 50);
        } else if (sidebarItems.length > 1) {
          setTimeout(() => {
            void this.router.navigate(['/select-organization']);
          }, 50);
          
        } else {
          if (item.children[0].type === 'page') {
            setTimeout(() => {
              void this.router.navigate([item.link]);
            }, 50);
          } else if (item.children.length > 1) {
            item = item.children[0];
            if (item.type === 'study') {
              setTimeout(() => {
                void this.router.navigate([`/organizations/${sidebarItems[0].id}/select-study`]);
              }, 50);
            }
          } else {
            const organizationId = sidebarItems[0].id;
            const studyId = item.children[0].id;
            setTimeout(() => {
              void this.router.navigate([`organizations/${organizationId}/select-study`]);
            }, 50);
          }
        }
      }
      )).subscribe();
  }

  public logout(): void {
    this.authService.logout();
  }
}
