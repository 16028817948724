import { Component, OnDestroy, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApplicationParameterDTO } from '../../dtos/application-parameter.dto';
import { AuthService } from '../../services/auth.service';
import { OperationState } from '../../store/operation/operation.state';
import { StringEmpty } from '../../utils/global-vars';

@UntilDestroy()
@Component({
  selector: 'app-idle',
  template: StringEmpty
})
export class IdleComponent implements OnInit, OnDestroy {
  @Select(OperationState.getApplicationParameters)
  private readonly applicationParameters$: Observable<ApplicationParameterDTO[]>;

  constructor(
    private readonly idle: Idle,
    private readonly authService: AuthService,
    private readonly translateService: TranslateService,
    private readonly toastrService: ToastrService
  ) { }

  public ngOnInit(): void {

    this.applicationParameters$
      .pipe(
        filter(applicationParameters => !!applicationParameters),
        untilDestroyed(this)
      )
      .subscribe((applicationParameters) => {
        const sessionInactivity = applicationParameters.find(({ id }) => id === 'session-inactivity');
        const sessionInactivityWarning = applicationParameters.find(({ id }) => id === 'session-inactivity-warning');

        if (!sessionInactivity || !sessionInactivityWarning) {
          this.authService.logout();

          return;
        }

        const sessionIdle = parseInt(sessionInactivity.value, 10);
        const sessionTimeout = parseInt(sessionInactivityWarning.value, 10);

        this.idle.setIdle(sessionIdle);
        // inactivity for idle + timeout seconds = session expired
        this.idle.setTimeout(sessionTimeout);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.pipe(untilDestroyed(this)).subscribe(() => {
          this.toastrService.clear();
        });

        this.idle.onTimeout.pipe(untilDestroyed(this)).subscribe(() => {
            this.toastrService.clear();
            this.authService.logout('/error/session-expired');
        });

        this.idle.onIdleStart.pipe(untilDestroyed(this)).subscribe(() => {
          this.toastrService.warning(this.translateService.instant('platform.session.idle'), StringEmpty, {
            timeOut: (sessionTimeout) * 1000,
            progressBar: true
          });
        });

        this.toastrService.clear();
        this.idle.watch();
      });
  }

  public ngOnDestroy(): void {
    this.idle.stop();
  }
}
