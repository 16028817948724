import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { switchMap } from 'rxjs/operators';
import { IAuthentication } from '../../interfaces/authentication.interface';
import { silenceClientErrors } from '../../handlers/global-error.handler';
import { AuthService } from '../../services/auth.service';
import { StringEmpty } from '../../utils/global-vars';
import { GetPlatformRolesOperationsAction } from '../operation/operation.actions';
import { GetSidebarAction, ToggleSidebarItemAction } from '../sidebar/sidebar.actions';
import { LoginAction, LogoutAction, SaveUserTokenAction, SSOLoginAction, TwoFactorAuthAction } from './auth.actions';
import { AUTH_STATE_TOKEN } from './auth.token';

export interface IAuthState {
  username: string;
  access_token: string;
  refresh_token: string;
  tokenExpiration: string;
  is2StepVerificationRequired: boolean;
  twoFactorType: 'sms' | 'email' | 'authenticator';
  qrCodeUrl?: string;
  manualEntryCode?: string;
}

@State({
  name: AUTH_STATE_TOKEN,
  defaults: AuthState.defaultState
})
@Injectable()
export class AuthState {
  static defaultState: IAuthState = undefined;

  constructor(
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly router: Router
  ) { }

  @Selector()
  static getAuthUsername(state: IAuthState) {
    return state.username;
  }

  @Selector()
  static getTwoFactorType(state: IAuthState) {
    return state.twoFactorType;
  }

  @Selector()
  static getQRCodeUrl(state: IAuthState) {
    return state.qrCodeUrl;
  }

  @Selector()
  static getManualEntryCode(state: IAuthState) {
    return state.manualEntryCode;
  }

  @Action(LoginAction)
  authActionsLogin({ dispatch }: StateContext<IAuthState>, { username, password }: LoginAction) {
    return this.authService.login(username, password)
      .pipe(
        switchMap(authData => {
            const data: IAuthentication = {
              username,
              access_token: authData.token,
              refresh_token: authData.refreshToken,
              tokenExpiration: authData.tokenExpiration,
              is2StepVerificationRequired: authData.isTwoFactor,
              twoFactorType: authData.twoFactorType,
              qrCodeUrl: authData.qrCodeUrl,
              manualEntryCode: (authData.manualEntryCode || StringEmpty).replace(/(.{2})/g, '$1 ').trim(),
              expires_in: null,
              scope: null,
              token_type: null
            };

            if( !authData.isTwoFactor){
              return dispatch([new SaveUserTokenAction(data),
                GetPlatformRolesOperationsAction,
                GetSidebarAction]);
            }
            else{
              return dispatch(new SaveUserTokenAction(data));
            }
        }),
        silenceClientErrors
      );
  }

  @Action(SSOLoginAction)
  authActionsSsoLogin({ dispatch }: StateContext<IAuthState>, { jwtId }: SSOLoginAction) {
    return this.authService.loginSso(jwtId)
      .pipe(
        switchMap(authData => {
            const data: IAuthentication = {
              username: authData.Username,
              access_token: authData.token,
              refresh_token: authData.refreshToken,
              tokenExpiration: authData.tokenExpiration,
              is2StepVerificationRequired: authData.isTwoFactor,
              twoFactorType: authData.twoFactorType,
              qrCodeUrl: authData.qrCodeUrl,
              manualEntryCode: (authData.manualEntryCode || StringEmpty).replace(/(.{2})/g, '$1 ').trim(),
              expires_in: null,
              scope: null,
              token_type: null
            };
              return dispatch([new SaveUserTokenAction(data),
                GetPlatformRolesOperationsAction,
                GetSidebarAction]);

        }),
        silenceClientErrors
      );
  }


  @Action(LogoutAction)
  async authActionsLogout({ patchState, dispatch }: StateContext<IAuthState>, { redirectPath }: LogoutAction) {
    this.authService.setRedirectUrl(null);

    dispatch(new ToggleSidebarItemAction(StringEmpty));

    patchState({
      access_token: undefined,
      refresh_token: undefined
    });

    await this.router.navigate([redirectPath || '/login']);
  }

  @Action(SaveUserTokenAction)
  authActionsSaveUserToken(
    { patchState, getState }: StateContext<IAuthState>,
    { authData: { username, access_token, refresh_token, tokenExpiration, is2StepVerificationRequired, twoFactorType, manualEntryCode, qrCodeUrl }, update }: SaveUserTokenAction
  ) {
    if (update) {
      return patchState(Object.fromEntries(Object.entries({
        username,
        access_token,
        refresh_token,
        tokenExpiration,
        is2StepVerificationRequired,
        twoFactorType,
        manualEntryCode,
        qrCodeUrl
      }).filter(([_, value]) => !!value)));
    }

    return patchState({
      username,
      access_token,
      refresh_token,
      tokenExpiration,
      is2StepVerificationRequired,
      twoFactorType,
      manualEntryCode,
      qrCodeUrl
    });
  }

  @Action(TwoFactorAuthAction)
  twoFactorAuth({ dispatch }: StateContext<IAuthState>, twoFactorAuthAction: TwoFactorAuthAction) {
    return this.authService.twoFactorAuth(twoFactorAuthAction.twoFactorAuth).pipe(
      switchMap(authData => {
          const data: IAuthentication = {
            access_token: authData.token,
            refresh_token: authData.refreshToken,
            tokenExpiration: authData.tokenExpiration,
            is2StepVerificationRequired: authData.isTwoFactor,
            expires_in: null,
            scope: null,
            token_type: null
          };

          return dispatch([
            new SaveUserTokenAction(data),
            GetPlatformRolesOperationsAction,
            GetSidebarAction
          ]);
        }
      )
    );
  }
}
