import { Routes } from '@angular/router';
import { AuthGuard } from './platform/guards/auth.guard';
import { NoSidebarLayoutComponent } from './platform/layout/no-sidebar-layout/no-sidebar-layout.component';
import { HomeComponent } from './platform/pages/home/home.component';
import { StringEmpty } from './platform/utils/global-vars';
import { OnlyContentLayoutComponent } from './platform/shared/only-content-layout/only-content-layout.component';
import { UserResolver } from './platform/resolvers/user.resolver';
import { ApplicationsEnum } from './platform/dtos/application.dto';


export const appRoutes: Routes = [
  {
    path: StringEmpty,
    redirectTo: '/home',
    pathMatch: 'full'
  },

  // No authenticated Routes 
  {
    path: '',
    children: [
      {
        path: 'login',
        component: OnlyContentLayoutComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('src/app/platform/pages/login/login.module').then(m => m.LoginModule)
          }
        ]
      },
      {
        path: 'privacy-policy',
        component: NoSidebarLayoutComponent,
        loadChildren: () => import('src/app/platform/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
      },
    ]
  },


  // Authenticated Routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: { data: UserResolver },
    data: {
      operation: 'view-profile-page',
      application: ApplicationsEnum.PLATFORM,
    },
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: '',
        loadChildren: () => import('./platform/platform.module').then(m => m.PlatformModule)
      }
    ]
  },


  {
    path: 'error',
    component: NoSidebarLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/platform/pages/page-error/page-error.module').then(m => m.PageErrordModule),
      },

    ],
  },
  {
    path: '**',
    redirectTo: '/error/page-not-found',
  }
];

