import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CompleteRegistrationDTO } from '../dtos/complete-registration.dto';
import { EmailDTO } from '../dtos/email.dto';
import { LastPasswordChangeDTO } from '../dtos/last-password-change.dto';
import { LoginDTO } from '../dtos/login.dto';
import { LostPasswordDTO } from '../dtos/lost-password.dto';
import { RegisterDTO } from '../dtos/register.dto';
import { IRoleScope } from '../interfaces/role.scope.interface';
import { IUser } from '../model/user.model';
import { ChangePasswordDTO } from '../dtos/change-password.dto';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private readonly store: Store,
    private readonly httpClient: HttpClient
  ) { }

  public getCurrentUser(): Observable<IUser> {
    return this.httpClient.get<IUser>(`${environment.platformHost}/platform/self-user`);
  }

  public getUserById(username: string): Observable<IUser> {
    return this.httpClient.get<IUser>(`${environment.platformHost}/platform/user/${username}`);
  }

  public updateCurrentUser(user: IUser): Observable<IUser> {
    return this.httpClient.post<IUser>(`${environment.platformHost}/platform/user/update-self`, user);
  }

  public updateUser(username: string, user: IUser): Observable<IUser> {
    return this.httpClient.post<IUser>(`${environment.platformHost}/platform/user/${username}/update`, user);
  }

  // Method to request list of all Users
  public getAllUsers(): Observable<IUser[]> {
    return this.httpClient.get<IUser[]>(`${environment.platformHost}/platform/users`);
  }

  // Method to request list of Users in an Organization
  public getAllUsersByOrganization(organizationId: string, startPage: number, itemsPerPage: number, columnSort: string, filter: string) {
    if (columnSort === undefined || columnSort === null || columnSort.toLowerCase() === 'name asc') {
      columnSort = '&$orderby=Name asc';
    }
    else if (columnSort.toLowerCase() === 'name desc') {
      columnSort = '&$orderby=Name desc';
    }
    else {
      columnSort = `&$orderby=${columnSort}, Name asc`;
    }
  
    let odataQueryString = `?${filter}`;

    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      odataQueryString = odataQueryString + `&$top=${itemsPerPage}`;
    }

    if (startPage !== null && startPage !== undefined) {
      odataQueryString = odataQueryString + `&$skip=${(startPage - 1) * itemsPerPage}`;
    }

    return this.httpClient.get<IUser[]>(`${environment.platformHost}/platform/organization/${organizationId}/users${odataQueryString}`);
  }

  public getAllUsersByOrganizationCount(organizationId: string, filter: string): Observable<number> {
    const odataQueryString = `?${filter}&$count=true`;
    return this.httpClient.get<number>(`${environment.platformHost}/platform/organization/${organizationId}/users${odataQueryString}`);
  }

  // Method to request list of Users in an Organization
  // return this.httpClient.get<IUser[]>(`${environment.platformHost}/platform/organization/${organizationId}/application/${applicationId}/study/${studyId}/users`);
  public getAllUsersByStudyAndApplication(organizationId: string, studyId: string, applicationId: string) {
    return this.httpClient.get<IUser[]>(`${environment.dsmbHost}/dsmb/organization/${organizationId}/study/${studyId}/users`);
  }

  // Method to get all the users paged, an OData filter and sorting can be applied,
  // If no sorting is given a default is used
  public getAllUsersPaged(startPage: number, itemsPerPage: number, sorting: string, filter: string): Observable<IUser[]> {
    if (sorting === null || sorting.toLowerCase() === 'name asc') {
      sorting = '&$orderby=Name asc';
    }
    else if (sorting.toLowerCase() === 'name desc') {
      sorting = '&$orderby=Name desc';
    }
    else {
      sorting = `&$orderby=${sorting}, Name asc`;
    }
    const odataQueryString = `?${filter}&$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}${sorting}`;
    return this.httpClient.get<IUser[]>(`${environment.platformHost}/platform/users${odataQueryString}`);
  }

  public changePasswordSelf(passwordData: ChangePasswordDTO): Observable<void> {
    return this.httpClient.post<void>(`${environment.platformHost}/platform/user/change-self-password`, passwordData);
  }

  public changeUserPassword(username: string, passwordData: ChangePasswordDTO): Observable<void> { 
    return this.httpClient.post<void>(`${environment.platformHost}/platform/user/${username}/change-password`, passwordData);
  }

  public changePanelUserPassword(orgId: string, studyId: string, username: string, passwordData: ChangePasswordDTO): Observable<void> {
    return this.httpClient.post<void>(`${environment.dsmbHost}/dsmb/organization/${orgId}/study/${studyId}/user/${username}/change-password`, passwordData);
  }

  public getSelfLastPasswordChange(): Observable<LastPasswordChangeDTO> {
    return this.httpClient.get<LastPasswordChangeDTO>(`${environment.platformHost}/platform/user/last-self-password-change`);
  }

  public getUserLastPasswordChange(username: string): Observable<LastPasswordChangeDTO> {
    return this.httpClient.get<LastPasswordChangeDTO>(`${environment.platformHost}/platform/user/${username}/last-password-change`);
  }

  public getPanelUserLastPasswordChange(orgId: string, studyId: string, username: string): Observable<LastPasswordChangeDTO> {
    return this.httpClient.get<LastPasswordChangeDTO>(`${environment.dsmbHost}/dsmb/organization/${orgId}/study/${studyId}/user/${username}/last-password-change`);
  }

  public lostPassword(lostPassword: LostPasswordDTO): Observable<any> {
    // @ts-ignore
    return this.httpClient.post<any>(`${environment.platformHost}/platform/user/lost-password`, lostPassword, { responseType: 'text' });
  }

  public lostPasswordCode(lostPasswordCode: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.platformHost}/platform/user/lost-password-link/${lostPasswordCode}`);
  }

  public unlock(code: string): Observable<any> {
    return this.httpClient.get<any>(`${environment.platformHost}/platform/user/unlock/${code}`);
  }

  public completeLostPassword(passwordData: ChangePasswordDTO, lostPasswordCode: string): Observable<void> {
    return this.httpClient.post<void>(`${environment.platformHost}/platform/user/complete-lost-password/${lostPasswordCode}`, passwordData);
  }

  public getSelfLastLogin(): Observable<LoginDTO> {
    const odataQueryString = `?$top=1&$orderby=Timestamp desc`;

    return this.httpClient.get<LoginDTO[]>(
      `${environment.platformHost}/platform/user/logins${odataQueryString}`).pipe(
        map(x => x.length > 0 ? x[0] : null),
      );
  }

  public getSelfLogins(startPage: number, itemsPerPage: number, sorting: string): Observable<LoginDTO[]> {
    if (sorting === null || sorting.toLowerCase() === 'timestamp desc') {
      sorting = '&$orderby=Timestamp desc';
    }
    else if (sorting.toLowerCase() === 'timestamp asc') {
      sorting = '&$orderby=Timestamp asc';
    }
    else {
      sorting = `&$orderby=${sorting}, Timestamp desc`;
    }
    const odataQueryString = `?$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}${sorting}`;
    return this.httpClient.get<LoginDTO[]>(`${environment.platformHost}/platform/user/logins${odataQueryString}`);
  }

  public getUserLogins(username: string, startPage: number, itemsPerPage: number, sorting: string): Observable<LoginDTO[]> {
    if (!sorting || sorting.toLowerCase() === 'timestamp desc') {
      sorting = '&$orderby=Timestamp desc';
    }
    else if (sorting && sorting.toLowerCase() === 'timestamp asc') {
      sorting = '&$orderby=Timestamp asc';
    }
    else if (sorting) {
      sorting = `&$orderby=${sorting}, Timestamp desc`;
    }
    const odataQueryString = `?$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}${sorting}`;
    return this.httpClient.get<LoginDTO[]>(`${environment.platformHost}/platform/user/${username}/logins${odataQueryString}`);
  }

  public getUserLoginsCount(username: string): Observable<number> {
    const odataQueryString = `?$count=true`;
    return this.httpClient.get<number>(`${environment.platformHost}/platform/user/${username}/logins${odataQueryString}`);
  }

  // TODO: merge with getUserLogins
  public getPanelUserLogins(orgId: string, studyId: string, username: string, startPage: number, itemsPerPage: number, sorting: string): Observable<LoginDTO[]> {
    if (sorting === null || sorting.toLowerCase() === 'timestamp desc') {
      sorting = '&$orderby=Timestamp desc';
    }
    else if (sorting.toLowerCase() === 'timestamp asc') {
      sorting = '&$orderby=Timestamp asc';
    }
    else {
      sorting = `&$orderby=${sorting}, Timestamp desc`;
    }
    const odataQueryString = `?$top=${itemsPerPage}&$skip=${(startPage - 1) * itemsPerPage}${sorting}`;
    return this.httpClient.get<LoginDTO[]>(`${environment.dsmbHost}/dsmb/organization/${orgId}/study/${studyId}/user/${username}/logins${odataQueryString}`);
  }

  public getPanelUserLoginsCount(orgId: string, studyId: string, username: string): Observable<number> {
    const odataQueryString = `?$count=true`;
    return this.httpClient.get<number>(`${environment.dsmbHost}/dsmb/organization/${orgId}/study/${studyId}/user/${username}/logins${odataQueryString}`);
  }

  public getSelfLoginsCount(): Observable<number> {
    const odataQueryString = `?$count=true`;
    return this.httpClient.get<number>(`${environment.platformHost}/platform/user/logins${odataQueryString}`);
  }

  // method to get the number of user, an OData filter can be applied
  public getUsersCount(filter: string): Observable<number> {
    const odataQueryString = `?${filter}&$count=true`;
    return this.httpClient.get<number>(`${environment.platformHost}/platform/users${odataQueryString}`);
  }

  public activateUser(username: string): Observable<any> {
    return this.httpClient.get(`${environment.platformHost}/platform/user/${username}/activate`);
  }

  public deactivateUser(username: string): Observable<any> {
    return this.httpClient.get(`${environment.platformHost}/platform/user/${username}/deactivate`);
  }

  public deleteUser(userDTO: IUser): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/user/remove`, userDTO);
  }

  public registerUser(userDTO: RegisterDTO): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/user/register`, userDTO);
  }

  public registerUserCode(registrationCode: string): Observable<RegisterDTO> {
    return this.httpClient.get<RegisterDTO>(`${environment.platformHost}/platform/user/register-link/${registrationCode}`);
  }

  public completeRegistrationUser(registrationCode: string, completeRegistrationDTO: CompleteRegistrationDTO): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/user/complete-registration/${registrationCode}`, completeRegistrationDTO);
  }

  public getUserRoles(username: string): Observable<IRoleScope[]> {
    return this.httpClient.get<IRoleScope[]>(`${environment.platformHost}/platform/user/${username}/roles`);
  }

  public addRoleToUser(username: string, requestedRole: IRoleScope): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/user/${username}/role/add`, requestedRole);
  }

  public removeRoleFromUser(username: string, roleToRemove: IRoleScope): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/user/${username}/role/remove`, roleToRemove);
  }

  public activateUserRole(username: string, roleToActivate: IRoleScope): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/user/${username}/role/activate`, roleToActivate);
  }

  public deactivateUserRole(username: string, roleToDeactivate: IRoleScope): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/user/${username}/role/deactivate`, roleToDeactivate);
  }

  public changeEmail(email: EmailDTO): Observable<any> {
    return this.httpClient.post(`${environment.platformHost}/platform/user/change-self-email`, email, { responseType: 'text' });
  }

  public changeEmailCode(changeEmailCode: string): Observable<any> {
    return this.httpClient.get(`${environment.platformHost}/platform/user/change-email-link/${changeEmailCode}`);
  }

  public completeChangeEmailCode(changeEmailCode: string): Observable<any> {
    return this.httpClient.get(`${environment.platformHost}/platform/user/complete-change-email/${changeEmailCode}`);
  }

  public resendRegisterLink(userEmail: EmailDTO): any {
    return this.httpClient.post(`${environment.platformHost}/platform/user/resend-register-link`, userEmail, { responseType: 'text' });
  }

  public resendLostPasswordLink(userEmail: EmailDTO): any {
    return this.httpClient.post(`${environment.platformHost}/platform/user/resend-lost-password-link`, userEmail, { responseType: 'text' });
  }

  public resendChangeEmailLink(userEmail: EmailDTO): any {
    return this.httpClient.post(`${environment.platformHost}/platform/user/resend-change-email-link`, userEmail, { responseType: 'text' });
  }
}
