import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { combineLatest, filter, Observable, of, switchMap } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { GetUserInfoAction } from '../store/user/user.actions';
import { IUser } from '../model/user.model';
import { GetSelfOrganizationsAction } from '../store/organization/organization.actions';
import { IOrganization } from '../interfaces/organization.interface';
import { OrganizationState } from '../store/organization/organization.state';
import { StudyState } from '../store/study/study.state';
import { IStudy, StudyDTO } from '../dtos/study.dto';
import { GetSelfStudiesAction } from '../store/study/study.actions';
import { UserState } from '../store/user/user.state';
import { ApplicationParameterDTO } from '../dtos/application-parameter.dto';
import { OperationState } from '../store/operation/operation.state';
import { GetPlatformRolesOperationsAction } from '../store/operation/operation.actions';



@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<any> {

    @Select(UserState.getLoggedUser) public readonly loggedUser$: Observable<IUser>;
    @Select(OrganizationState.getSelfOrganizations) selfOrganizations$: Observable<IOrganization[]>;
    @Select(StudyState.getStudies) public readonly selfStudies$: Observable<StudyDTO[]>;
    @Select(OperationState.getApplicationParameters) public readonly applicationParameters$: Observable<ApplicationParameterDTO[]>;

  constructor(
    private readonly store: Store,
  ) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<any> {

    return of(true).pipe(
        switchMap(() => this.store.dispatch(new GetUserInfoAction())),
        switchMap(() => this.loggedUser$),
        filter((user) => !!user),
        switchMap((user) => {
            return combineLatest([of(user), this.selfOrganizations$])
        }),
        switchMap(([user, selfOrganizations]: [IUser, IOrganization[]]) => {

            if (!user.isSuper() && (!selfOrganizations || selfOrganizations.length == 0) ) {
                return this.store.dispatch(new GetSelfOrganizationsAction()).pipe(
                    switchMap(() =>  of(user))
                )
            } 
            return of(user);
        }),
        switchMap((user) => {
            return combineLatest([of(user), this.selfStudies$])
        }),
        switchMap(([user, selfStudies]: [IUser, IStudy[]]) => {

            if (!user.isSuper() && (!selfStudies || selfStudies.length == 0) ) {
                return this.store.dispatch(new GetSelfStudiesAction()).pipe(
                    switchMap(() =>  of(true))
                )
            } 
            return of(true);
        }),
        switchMap(_ => {
            return this.applicationParameters$
        }),
        switchMap((appParam: ApplicationParameterDTO[]) => {
            if (appParam.length == 0) {
                return this.store.dispatch(new GetPlatformRolesOperationsAction).pipe(
                    switchMap(() =>  of(true))
                );
            }
            return of(true);
        })
    );
  }
}
